<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="手机号码">
          <a-input
            v-model:value="params.phone"
            placeholder="输入用户手机号"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="用户昵称">
          <a-input
            v-model:value="params.nickName"
            placeholder="输入用户昵称"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item label="车牌号">
          <a-input
            v-model:value="params.plateNumber"
            placeholder="输入车牌号码"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          bordered
          :scroll="{ y: 500 }"
        >
          <template #operation="{ record }">
            <TipButton
              perm="member:list:edit"
              btn-class="btn-success"
              title="详情"
              @btnClick="showDetailModal(record.id)"
            >
              <template #default>
                <ContainerOutlined />
              </template>
            </TipButton>
          </template>

          <template #subscribeState="{ text }">
            <a-tag v-if="text == 0" color="red">否</a-tag>
            <a-tag v-if="text == 1" color="green">是</a-tag>
          </template>
          <template #plateNumbers="{ text }">
            <div v-for="item in text" :key="item.plateNumber">
              <a-tag :color="plateColorStyle(item.plateColor)">
                <span
                  :style="{
                    color: item.plateColor == 4 ? '#001510' : '#FFFFFF',
                  }"
                  >{{ item.plateNumber }}</span
                ></a-tag
              >
            </div>
          </template>

          <template #oweAmount="{ text }">
            {{ getoweAmount(text) }}
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>
  <detail
    v-if="detailShow"
    :id="currentId"
    :modal-ref="$refs.modalContainer"
    @close="hideDetailShow"
  ></detail>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import useList from "@/hooks/member/useList";
import usePagination from "@/hooks/usePagination";
import useAddExit from "@/hooks/useAddExit";
import { onMounted, ref } from "vue";
import Detail from "@/views/Member/List/components/Detail";
import TipButton from "@/components/TipButton";

export default {
  name: "index",
  components: {
    Detail,
    CardHeader,
    TipButton,
  },
  setup() {
    const {
      params,
      dataList,
      total,
      loading,
      columns,
      loadData,
      getoweAmount,
    } = useList();

    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);

    const detailShow = ref(false);
    const showDetailModal = id => {
      currentId.value = id;
      detailShow.value = true;
    };

    const hideDetailShow = () => {
      detailShow.value = false;
    };

    const { currentId } = useAddExit(doQuery);
    const plateColorStyle = val => {
      switch (val) {
        case 0:
          return "#888888";
        case 1:
          return "#0000FF";
        case 2:
          return "#FFA500";
        case 3:
          return "#000000";
        case 4:
          return "#FFFFFF";
        case 5:
          return "#008000";
        default:
          return "#FF0000";
      }
    };

    onMounted(() => {
      loadData(page.value, pageSize.value);
    });

    return {
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,
      currentId,
      detailShow,
      showDetailModal,
      hideDetailShow,
      getoweAmount,
      plateColorStyle,
    };
  },
};
</script>

<style scoped></style>
